import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { throttle } from 'lodash';
import { DEFAULT_NAVBAR_HEIGHT } from '../constants';

const THROTTLE_TIMEOUT = 350;

function NavbarOffset({ navbarRef }) {
  const [height, setHeight] = useState(
    navbarRef.current?.clientHeight || DEFAULT_NAVBAR_HEIGHT,
  );
  useEffect(() => {
    if (!navbarRef.current) {
      return;
    }

    const resizeObserverCallback = throttle(([navbarEntry]) => {
      setHeight(navbarEntry.contentRect.height);
    }, THROTTLE_TIMEOUT);

    const resizeObserver = new ResizeObserver(resizeObserverCallback);

    resizeObserver.observe(navbarRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <div
      className="nav-offset"
      style={{
        height: `${height}px`,
      }}
    />
  );
}

NavbarOffset.propTypes = {
  navbarRef: PropTypes.object.isRequired,
};

export default NavbarOffset;
