import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

function Title({ component: Component = 'h1', size, className, ...props }) {
  return (
    <Component
      className={clsx('title', className, `title_size-${size}`)}
      {...props}
    />
  );
}

Title.propTypes = {
  component: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.oneOf(['md', 'lg']),
};

export default Title;
