import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

export const Modal = ({ isOpen, children, onClose, className, id }) => {
  const onWrapperClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div
      className={clsx('modal__wrapper', className, isOpen && 'active')}
      onClick={onWrapperClick}
      id={id}
    >
      <div className="modal">
        <button className="modal__close-button" type="button" onClick={onClose}>
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M23 23.001L9 9.00098M9 23.001L23 9.00098L9 23.001Z"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
        {children}
      </div>
    </div>
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool,
  children: PropTypes.node,
  onClose: PropTypes.func,
  className: PropTypes.string,
  id: PropTypes.string,
};
