import React from 'react';
import backgroundLogo from '../images/background-icons.svg';
import { HeavyImage } from './heavy-image';

export function BackgroundLogo() {
  return (
    <HeavyImage
      src={backgroundLogo}
      alt="Background decoration"
      className="background background_logo"
    />
  );
}
