// import { apiPost } from '../api';
import { hubspotSaveLead, hubspotSubscribeNews } from '../hubspot';

export const postContactMe = async ({ name, email, phone, message }) => {
  const response = await hubspotSaveLead({ name, email, phone, message });
  return response;

  // return apiPost(`${process.env.GATSBY_API_URL}/contact-me`, {
  //   page: 'Contact Me Form',
  //   name,
  //   email,
  //   ...(phone && { phone }),
  //   ...(message && { message }),
  // });
};

export const postSubscribeNews = async (data) => {
  const response = await hubspotSubscribeNews(data);
  return response;

  // return apiPost(`${process.env.GATSBY_API_URL}/contact-me`, {
  //   ...data,
  //   page: 'Blog Subscription Form',
  // });
};
