import { useLayoutEffect } from 'react';
import { isWindow } from './is-env';
import { DEFAULT_NAVBAR_HEIGHT } from '../constants';

const smoothScrolling = (element) => {
  if (!element) {
    return;
  }

  return element.scrollIntoView({
    behavior: 'smooth',
  });
};

export const initAnchorsScrolling = () => {
  if (!isWindow()) {
    return;
  }
  document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
    anchor.addEventListener('click', (e) => {
      e.preventDefault();

      const href = anchor.getAttribute('href');
      const element = document.querySelector(href);

      if (!element) {
        return;
      }

      window.location.hash = href;
      smoothScrolling(element);
    });
  });
};

const initScrollingFromUrlHash = () => {
  if (!isWindow()) {
    return;
  }

  if (!window.location.hash) {
    return;
  }

  const element = document.querySelector(window.location.hash);

  if (!element) {
    return;
  }

  window.scrollTo(0, element.offsetTop - DEFAULT_NAVBAR_HEIGHT);
};

export const useAnchorsScrollingEffect = () => {
  useLayoutEffect(() => {
    initAnchorsScrolling();
    initScrollingFromUrlHash();
  }, []);
};
