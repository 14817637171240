import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

export function Input({
  id,
  label,
  leftIcon,
  rightIcon,
  className,
  otherClassNames,
  error,
  ...props
}) {
  return (
    <div
      className={clsx(
        'input',
        error && 'input_error',
        leftIcon && 'input_icon-left',
        rightIcon && 'input_icon-right',
        className,
      )}
    >
      {label && (
        <label
          className={clsx('input__label', otherClassNames?.label)}
          htmlFor={id}
        >
          {label}
        </label>
      )}
      <input className={clsx('input__input', className)} id={id} {...props} />
      {leftIcon && (
        <div
          className={clsx(
            'input__icon',
            'input__icon_left',
            otherClassNames?.iconLeft,
          )}
        >
          {leftIcon}
        </div>
      )}
      {rightIcon && (
        <div
          className={clsx(
            'input__icon',
            'input__icon_right',
            otherClassNames?.iconRight,
          )}
        >
          {rightIcon}
        </div>
      )}
    </div>
  );
}

Input.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  leftIcon: PropTypes.node,
  rightIcon: PropTypes.node,
  className: PropTypes.string,
  error: PropTypes.string,
  size: PropTypes.oneOf(['small', 'normal', 'large']),
  otherClassNames: PropTypes.objectOf({
    input: PropTypes.string,
    label: PropTypes.string,
    iconLeft: PropTypes.string,
    iconRight: PropTypes.string,
  }),
};
