import { useEffect, useState } from 'react';

export const useForm = ({ values, validators }) => {
  const [form, setForm] = useState({ ...values });
  const [errors, setErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabledControl, setIsDisabledControl] = useState(false);

  const hasError = (name) => errors && errors.includes(name);

  const onSetFormValue = (name, value) => {
    setForm((preValues) => ({
      ...preValues,
      [name]: value,
    }));
  };

  const initValidation = (name, value) => {
    if (validators && validators[name]) {
      const isValid = validators[name](value);

      if (isValid) {
        return setErrors((prevErrors) =>
          prevErrors.filter((item) => item !== name),
        );
      }

      if (!errors.includes(name)) {
        return setErrors((prevState) => [...prevState, name]);
      }
    }
  };

  const onChangeInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setForm((preValues) => ({
      ...preValues,
      [name]: value,
    }));

    hasError(name) && initValidation(name, value);
  };

  const onBlurInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    initValidation(name, value);
  };

  const onResetForm = () => {
    setForm(values);
    setIsLoading(false);
    setIsDisabledControl(false);
  };

  useEffect(() => {
    setIsDisabledControl(Boolean(errors && errors.length));
  }, [errors]);

  return {
    form,
    onResetForm,
    onSetFormValue,
    onChangeInput,
    errors,
    hasError,
    onBlurInput,
    isDisabledControl,
    setIsDisabledControl,
    isLoading,
    setIsLoading,
  };
};
