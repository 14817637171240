import React from 'react';
import { ModalMessage } from './modal-message';
import { ModalSuccess } from './modal-success';

export const ModalManager = () => {
  return (
    <>
      <ModalMessage />
      <ModalSuccess />
    </>
  );
};
