import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import clsx from 'clsx';

function Button({
  to,
  className,
  colorScheme = 'main',
  size = 'normal',
  disabled = false,
  isLoading = false,
  children,
  ...props
}) {
  const Component = to ? Link : 'button';

  return (
    <Component
      to={to}
      disabled={disabled}
      className={clsx('btn', `btn_${colorScheme}`, `btn_${size}`, className)}
      {...props}
    >
      {isLoading ? 'Loading...' : children}
    </Component>
  );
}

Button.propTypes = {
  to: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  colorScheme: PropTypes.oneOf(['main', 'secondary']),
  size: PropTypes.oneOf(['small', 'normal', 'large']),
};

export default Button;
