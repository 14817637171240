import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

function Container({ children, className }) {
  return <div className={clsx('container', className)}>{children}</div>;
}

Container.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Container;
