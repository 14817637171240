import React, { useState } from 'react';

import { Modal } from '../../components/modal';
import { MODALS } from '../../context/modal';
import { useForm, useModal } from '../../hooks';
import Title from '../../components/title';
import Button from '../../components/button';
import { validators } from '../../utils';
import clsx from 'clsx';
import { postContactMe } from '../../api';
import checkCaptcha from '../../utils/check-captcha';

const MODAL_ID = MODALS.MODAL_MESSAGE;

export const ModalMessage = () => {
  const { isShow, closeModal } = useModal(MODAL_ID);
  const { openModal: showSuccessModal } = useModal(MODALS.MODAL_SUCCESS);
  const [serverError, setServerError] = useState(null);

  const {
    form,
    isLoading,
    setIsLoading,
    setIsDisabledControl,
    isDisabledControl,
    hasError,
    onBlurInput,
    onChangeInput,
    onResetForm,
  } = useForm({
    values: { name: '', email: '', phone: '', message: '' },
    validators: {
      phone: validators.phone,
    },
  });

  const onSubmit = async (e) => {
    try {
      e.preventDefault();
      await checkCaptcha();

      const { name, email, phone, message } = form;

      setServerError(null);
      setIsLoading(true);
      setIsDisabledControl(true);

      await postContactMe({
        name,
        email,
        phone,
        message,
      });
      showSuccessModal(true);
      onResetForm();
    } catch (e) {
      console.log('e', e)
      setIsLoading(false);
      setIsDisabledControl(false);
      setServerError('Something went wrong...');
    }
  };

  return (
    <Modal isOpen={isShow} onClose={closeModal} id={MODAL_ID}>
      <Title size="md" className="modal__title">
        Send us a message
      </Title>
      <form className="form" onSubmit={onSubmit}>
        <label className="form__label">
          <span className="form__label-text">Full Name</span>
          <input
            name="name"
            className="form__input"
            placeholder="Name"
            required
            onChange={onChangeInput}
            value={form.name}
          />
        </label>

        <label className="form__label">
          <span className="form__label-text">Email</span>
          <input
            name="email"
            type="email"
            className="form__input"
            placeholder="Email"
            required
            onChange={onChangeInput}
            value={form.email}
          />
        </label>

        <label className="form__label">
          <span className="form__label-text">Phone</span>
          <input
            name="phone"
            type="tel"
            className={clsx(
              'form__input',
              hasError('phone') && 'form__input_error',
            )}
            placeholder="ex. +380941455154"
            required
            onChange={onChangeInput}
            onBlur={onBlurInput}
            value={form.phone}
          />
          {hasError('phone') && (
            <span className="form__error">Wrong phone number!</span>
          )}
        </label>

        <label className="form__label">
          <span className="form__label-text">Comment</span>
          <textarea
            name="message"
            className="form__input"
            placeholder="Your message"
            cols={5}
            onChange={onChangeInput}
            value={form.message}
          />
        </label>

        <div className="form__submit">
          <Button
            type="submit"
            isLoading={isLoading}
            disabled={isDisabledControl}
          >
            Send
          </Button>
        </div>

        {serverError && <span className="form__error">{serverError}</span>}
      </form>
    </Modal>
  );
};
