import { useState, useCallback, useMemo, useEffect } from 'react';
import { POSTS_PER_PAGE } from '../constants';

export function usePostsPagination(
  allPosts = [],
  postsPerPage = POSTS_PER_PAGE,
) {
  const [posts, setPosts] = useState(allPosts.slice(0, postsPerPage));

  useEffect(() => {
    setPosts(allPosts.slice(0, postsPerPage));
  }, [allPosts, postsPerPage]);

  const hasMore = useMemo(
    () => posts.length < allPosts.length,
    [allPosts.length, posts.length],
  );

  const handleLoadMore = useCallback(() => {
    setPosts((prev) => {
      const currentLength = prev.length;
      const isMore = currentLength < allPosts.length;
      const nextResults = isMore
        ? allPosts.slice(currentLength, currentLength + postsPerPage)
        : [];
      return [...prev, ...nextResults];
    });
  }, [allPosts, postsPerPage]);

  return { posts, hasMore, handleLoadMore };
}
