import React, {
  createContext,
  useContext,
  useMemo,
  useState,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import { SCROLL_SHOW_FALLBACK_TIMEOUT } from '../../constants';

const ModalContext = createContext({});

export const ModalContextProvider = ({ children }) => {
  const [activeModalIds, setActiveModalIds] = useState([]);
  const timeoutRef = useRef(null);

  const closeAllModals = () => {
    setActiveModalIds([]);
  };

  const openModalById = (id) => {
    if (!activeModalIds.includes(id)) {
      setActiveModalIds((prevState) => [...prevState, id]);
    }
  };

  const closeModalById = (id, onClose) => {
    setActiveModalIds((prevState) => prevState.filter((item) => item !== id));

    const modalRef = document.getElementById(id);
    if (!modalRef) {
      onClose?.();
      return;
    }

    const handleAnimationEnd = (e) => {
      onClose?.(e);
      modalRef.removeEventListener('transitionend', handleAnimationEnd);
    };
    modalRef.addEventListener('transitionend', handleAnimationEnd);

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(
      handleAnimationEnd,
      SCROLL_SHOW_FALLBACK_TIMEOUT,
    );
  };

  const value = useMemo(
    () => ({
      openModalById,
      closeModalById,
      activeModalIds,
      closeAllModals,
    }),
    [activeModalIds, openModalById, closeModalById, closeAllModals],
  );

  return (
    <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
  );
};

ModalContextProvider.propTypes = {
  children: PropTypes.node,
};

export const useModalContext = () => useContext(ModalContext);
