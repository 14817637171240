const checkCaptcha = () => {
  return new Promise((resolve, reject) => {
    window?.grecaptcha?.ready(() => {
      window.grecaptcha
        .execute('6LfeNBIqAAAAAPaGG1VKBGFMlEGcW_JD1zsRmKme', {
          action: 'submit',
        })
        .then(() => resolve())
        .catch(reject);
    });
  });
};

export default checkCaptcha;
