import { useModalContext } from '../context/modal';
import { hideScroll, showScroll } from '../utils';

export const useModal = (id) => {
  const { activeModalIds, openModalById, closeModalById, closeAllModals } =
    useModalContext();

  const isShow = activeModalIds.includes(id);

  const openModal = () => {
    hideScroll();
    openModalById(id);
  };

  const closeModal = () => {
    closeModalById(id, showScroll);
  };

  const closeActiveModals = () => {
    closeAllModals();
    showScroll();
  };

  return {
    isShow,
    openModal,
    closeModal,
    closeActiveModals,
  };
};
