// import { isDevelopment } from '../../utils';
import { submitHubspotForm } from './submit-form';

const generateConditionalField = (name, value) => {
  return value
    ? [
        {
          name,
          value,
        },
      ]
    : [];
};

export const hubspotSaveLead = ({ email, phone, name, message }) => {
  // if (isDevelopment()) {
  //   return;
  // }

  const credentials = {
    id: process.env.GATSBY_HUBSPOT_PORTAL_ID,
    formId: process.env.GATSBY_HUBSPOT_FORM_ID,
  };

  const fields = [
    {
      name: 'firstname',
      value: name,
    },
    {
      name: 'lastname',
      value: '',
    },
    {
      name: 'email',
      value: email,
    },
    ...generateConditionalField('mobile_phone', phone),
    ...generateConditionalField('message', message),
  ];

  return submitHubspotForm(fields, credentials);
};

export const hubspotSubscribeNews = ({ name, email, job, company }) => {
  const credentials = {
    id: process.env.GATSBY_HUBSPOT_PORTAL_ID,
    formId: process.env.GATSBY_HUBSPOT_FORM_SUBSCRIBE_ID,
  };

  const fields = [
    {
      name: 'firstname',
      value: name,
    },
    {
      name: 'lastname',
      value: '',
    },
    {
      name: 'email',
      value: email,
    },
    ...generateConditionalField('jobtitle', job),
    ...generateConditionalField('company', company),
  ];

  return submitHubspotForm(fields, credentials);
};
