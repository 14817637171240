import { isSafariBrowser } from '../utils';
import React from 'react';
import PropTypes from 'prop-types';

export const HeavyImage = ({ src, alt, className }) => {
  if (isSafariBrowser()) {
    return;
  }

  return <img src={src} alt={alt} className={className} />;
};

HeavyImage.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  className: PropTypes.string,
};
