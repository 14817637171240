import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Button from './button';
import Container from './container';
import NavbarOffset from './navbar-offset';
import logoText from '../images/logo-text.svg';
import logoIcon from '../images/logo-icon.svg';
import menuIcon from '../images/menu-icon.svg';
import closeIcon from '../images/close-icon.svg';
import { hideScroll, showScroll } from '../utils';
import { NAV_MENU, SCROLL_SHOW_FALLBACK_TIMEOUT } from '../constants';
import { useCheckScrollOffset } from '../hooks';

function Navbar({ disableShadow }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const isNavbarActive = useCheckScrollOffset(100);

  const navbarRef = useRef(null);
  const timeoutRef = useRef(null);

  const toggleMenu = () => setIsMenuOpen((prev) => !prev);
  const closeMenu = () => setIsMenuOpen(false);

  useEffect(() => {
    if (isMenuOpen) {
      hideScroll();
    } else {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(showScroll, SCROLL_SHOW_FALLBACK_TIMEOUT);
    }
  }, [isMenuOpen]);

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const handleMenuTransitionEnd = () => {
    if (!isMenuOpen) {
      showScroll();
    }
  };

  return (
    <>
      <div
        className={clsx(
          'nav__wrapper',
          isNavbarActive && !disableShadow && 'active',
        )}
        ref={navbarRef}
      >
        <Container>
          <nav className="nav">
            <Link
              to="/"
              className="nav__logo-link"
              data-sal="slide-right"
              onClick={closeMenu}
            >
              <img src={logoText} alt="Freystaff" className="nav__logo-text" />
              <img src={logoIcon} alt="Freystaff" className="nav__logo-icon" />
            </Link>

            <div
              className={clsx('nav__menu', isMenuOpen && 'active')}
              onTransitionEnd={handleMenuTransitionEnd}
            >
              <div className="nav__menu-header">
                <Link to="/" className="nav__menu-logo" onClick={closeMenu}>
                  <img src={logoText} alt="Freystaff" />
                </Link>

                <button
                  type="button"
                  className="nav__menu-close"
                  onClick={closeMenu}
                >
                  <img src={closeIcon} alt="Close menu" />
                </button>
              </div>

              <ul className="nav__list" onClick={closeMenu}>
                {NAV_MENU.map(({ link, name }, i) => (
                  <li
                    key={i}
                    className="nav__item"
                    data-sal="slide-down"
                    data-sal-delay={i * 100}
                  >
                    <Link to={link} className="nav__link">
                      {name}
                    </Link>
                  </li>
                ))}
              </ul>

              <Button
                className="nav__menu-contact"
                to={process.env.GATSBY_CONTACT_HUBSPOT_URL}
                target="_blank"
                rel="noreferrer noopener"
              >
                Contact Us
              </Button>
            </div>

            <div
              data-sal="slide-left"
              data-sal-delay="100"
              className="nav__contact-wrapper"
            >
              <Button
                className="nav__contact"
                to={process.env.GATSBY_CONTACT_HUBSPOT_URL}
                target="_blank"
                rel="noreferrer noopener"
              >
                Contact Us
              </Button>
            </div>

            <button type="button" className="nav__toggler" onClick={toggleMenu}>
              <img src={menuIcon} alt="Open menu" />
            </button>
          </nav>
        </Container>
      </div>

      <NavbarOffset navbarRef={navbarRef} />
    </>
  );
}

Navbar.propTypes = {
  disableShadow: PropTypes.bool,
};

export default Navbar;
