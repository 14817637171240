import React from 'react';
import Container from './container';
import logoText from '../images/logo-text.svg';
import { Link } from 'gatsby';
import { NAV_MENU, SOCIALS } from '../constants';

import facebookImage from '../images/facebook.svg';
import linkedinImage from '../images/linkedin.svg';

const socialsImageMapper = {
  facebook: facebookImage,
  linkedin: linkedinImage,
};

function Footer() {
  return (
    <footer className="footer">
      <Container>
        <div className="footer__row">
          <div className="footer__logo">
            <Link to="/">
              <img src={logoText} alt="Freystaff" />
            </Link>
          </div>
          <ul className="footer__links">
            {NAV_MENU.map((item) => (
              <li key={item.link}>
                <Link to={item.link}>{item.name}</Link>
              </li>
            ))}
          </ul>
          <ul className="footer__social">
            {SOCIALS.map((item, index) => (
              <li key={index}>
                <a
                  href={item.link}
                  target="_blank"
                  className="footer__social-item"
                  rel="noreferrer"
                >
                  <img src={socialsImageMapper[item.id]} alt={item.id} />
                </a>
              </li>
            ))}
          </ul>
        </div>
      </Container>
    </footer>
  );
}

export default Footer;
