import React from 'react';
import PropTypes from 'prop-types';
import Navbar from '../components/navbar';
import Footer from '../components/footer';
import { ModalContextProvider } from '../context/modal';
import { BackgroundLogo } from '../components';
import { ModalManager } from '../modules/modals';

console.log(`API base url: ${process.env.GATSBY_API_URL}`);

function RootLayout({ children, pageContext }) {
  return (
    <ModalContextProvider>
      <Navbar disableShadow={pageContext.disableNavbarShadow} />
      <BackgroundLogo />
      {children}
      <Footer />
      <ModalManager />
    </ModalContextProvider>
  );
}

RootLayout.propTypes = {
  children: PropTypes.node,
  pageContext: PropTypes.shape({
    disableNavbarShadow: PropTypes.bool,
  }),
};

export default RootLayout;
