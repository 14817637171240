export const ROUTES = {
  HOME: '/',
  ABOUT_US: '/about-us',
  HOW_IT_WORKS: '/how-it-works',
  PLANS_PRICING: '/plans-pricing',
  BLOG: '/blog',
};

export const ROUTES_NAMES = {
  [ROUTES.HOME]: 'Home',
  [ROUTES.ABOUT_US]: 'About Us',
  [ROUTES.HOW_IT_WORKS]: 'How It Works',
  [ROUTES.PLANS_PRICING]: 'Plans & Pricing',
  [ROUTES.BLOG]: 'Blog',
};

export const NAV_MENU = [
  {
    link: ROUTES.HOME,
    name: ROUTES_NAMES[ROUTES.HOME],
  },
  // {
  //   link: ROUTES.ABOUT_US,
  //   name: ROUTES_NAMES[ROUTES.ABOUT_US],
  // },
  {
    link: ROUTES.HOW_IT_WORKS,
    name: ROUTES_NAMES[ROUTES.HOW_IT_WORKS],
  },
  // {
  //   link: ROUTES.PLANS_PRICING,
  //   name: ROUTES_NAMES[ROUTES.PLANS_PRICING],
  // },
  {
    link: ROUTES.BLOG,
    name: ROUTES_NAMES[ROUTES.BLOG],
  },
];

export const SOCIALS = [
  {
    id: 'linkedin',
    link: 'https://www.linkedin.com/company/freystaff/',
  },
  {
    id: 'facebook',
    link: 'https://www.facebook.com/freystaff',
  },
];

export const POSTS_PER_PAGE = 12;
export const DEFAULT_NAVBAR_HEIGHT = 112;
export const SMALL_NAVBAR_HEIGHT = 72;
export const NAVBAR_OFFSET_MARGIN = 15;

export const BREAKPOINTS = {
  XS: 0,
  SM: 576,
  MD: 768,
  LG: 992,
  XL: 1200,
  XXL: 1400,
};

export const SCROLL_SHOW_FALLBACK_TIMEOUT = 350;
