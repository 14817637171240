export function hideScroll() {
  const desiredClassName =
    window.innerWidth - document.body.offsetWidth
      ? 'hidden-with-padding'
      : 'hidden';

  document.querySelector('html').classList.add(desiredClassName);
}

export function showScroll() {
  document.querySelector('html').classList.remove('hidden-with-padding');
  document.querySelector('html').classList.remove('hidden');
}
