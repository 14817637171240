import { apiPost } from '../api';
// import { isDevelopment } from '../../utils';

export const submitHubspotForm = (fields, { id, formId }) => {
  // if (isDevelopment()) {
  //   return;
  // }

  return apiPost(
    `${process.env.GATSBY_HUBSPOT_API_URL}/integration/submit/${id}/${formId}`,
    { fields },
  );
};
