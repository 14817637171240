import { MODALS } from '../../context/modal';
import { useModal } from '../../hooks';
import { Modal } from '../../components/modal';
import Title from '../../components/title';
import React from 'react';
import Button from '../../components/button';

import successIcon from '../../images/success-icon.svg';

const MODAL_ID = MODALS.MODAL_SUCCESS;

export const ModalSuccess = () => {
  const { isShow, closeActiveModals } = useModal(MODAL_ID);

  const onClickHandler = () => {
    closeActiveModals();
  };

  return (
    <Modal
      isOpen={isShow}
      onClose={onClickHandler}
      id={MODAL_ID}
      className="modal-success"
    >
      <Title size="md" className="modal__title">
        Your message has been sent
      </Title>
      <img src={successIcon} alt="success" className="modal-success__image" />
      <Button onClick={onClickHandler} className="modal-success__button">
        Close
      </Button>
    </Modal>
  );
};
