import { isWindow } from './is-env';

export const checkUserAgent = (regExp, userAgentRaw) => {
  const userAgent = userAgentRaw.toLowerCase();

  if (!(regExp || regExp instanceof RegExp)) {
    throw new Error(
      `${JSON.stringify(
        regExp,
      )} isn't instance of RegExp, need pass only instance of
      RegExp - /xxx/ or new RegExp('xxx', flags)`,
    );
  }

  return regExp.test(userAgent);
};

export const isSafariBrowser = () => {
  if (!isWindow()) {
    return;
  }

  return (
    checkUserAgent(/safari/, window.navigator.userAgent) &&
    !checkUserAgent(/chrome/, window.navigator.userAgent)
  );
};
